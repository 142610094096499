// project import
// import pages from './pages';
import {dashboardsec1, dashboardsec2} from './dashboard';
// import utilities from './utilities';
// import support from './support';
import {rearrange} from './Rearrange';
import {SEO,webTitle} from './Other';

// ==============================|| MENU ITEMS ||============================== //
const selectedThemeNumber = localStorage.getItem('selectedTheme');


const menuItems = {
  items:[dashboardsec1,dashboardsec2, SEO, webTitle]
};


export default menuItems;
