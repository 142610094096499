import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import Breadcrumbs from '../../components/@extended/Breadcrumbs';
// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from '../../menu-items/index';
import { useNavigate } from 'react-router-dom';
// types
import { openDrawer } from '../../store/reducers/menu';
import { Helmet } from 'react-helmet';
import axios from 'axios';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const [Favicon, setFaviconData] = useState();
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  const getFavicondata = () => {
    axios
      .get('http://146.190.32.117/api/favicon')
      .then((res) => {
        setFaviconData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/');
    }
  });
  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);


  
  useEffect(() => {
    getFavicondata();
  }, []);

  return (
    <>
      {' '}
      <Helmet>
        <link rel="icon" href={Favicon?.faviimgurl ? Favicon?.faviimgurl : Favicon?.faviimgfile || ''} />
      </Helmet>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Toolbar />
          <Breadcrumbs navigation={navigation} title />
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
