// assets
import { DashboardOutlined } from '@ant-design/icons';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ContrastIcon from '@mui/icons-material/Contrast';

// icons
const icons = {
  DashboardOutlined,
  PersonAddAltOutlinedIcon,
  ContrastIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/mainlayout/Admindashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'CreateUser',
      title: 'Create User',
      type: 'item',
      url: `/mainlayout/createUser`,
      icon: icons.PersonAddAltOutlinedIcon,
      breadcrumbs: false,
     
    },
    {
      id: 'createTemplate',
      title: 'Create Template',
      type: 'item',
      url: `/mainlayout/AddTemplate`,
      icon: icons.ContrastIcon,
      breadcrumbs: false,
     
    },
    // /mainlayout/createTemplate
  ]
};

export default dashboard;
