// assets
import { SearchOutlined, FontSizeOutlined, ApiOutlined, DashboardOutlined } from '@ant-design/icons';
import DiamondIcon from '@mui/icons-material/Diamond';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import FeaturedOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import Brightness5OutlinedIcon from '@mui/icons-material/Brightness5Outlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import SmartButtonOutlinedIcon from '@mui/icons-material/SmartButtonOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';

// icons
const icons = {
  SearchOutlined,
  FontSizeOutlined,
  DiamondIcon,
  ApiOutlined,
  PolicyOutlinedIcon,
  FeaturedOutlinedIcon,
  BookmarkOutlinedIcon,
  Brightness5OutlinedIcon,
  ContrastOutlinedIcon,
  SmartButtonOutlinedIcon,
  DashboardOutlined,
  ArrowLeftOutlinedIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const storedTheme = localStorage.getItem('currentTheme');
const SEO = {
  id: 'settings',
  // title: 'Settings',
  type: 'collapse',
  type: 'group',
  icon: icons.ApiOutlined,
  children: [
    {
      id: 'settings',
      title: 'Settings',
      type: 'collapse',
      icon: icons.ArrowLeftOutlinedIcon,

      children: [
        {
          id: 'seo',
          title: 'SEO',
          type: 'item',
          url: `/mainlayout/${storedTheme}/seo`,
          icon: icons.SearchOutlined,
          breadcrumbs: false,
         
        },
        {
          id: 'plugin',
          title: 'Plugin',
          type: 'item',
          url: `/mainlayout/${storedTheme}/plugin`,
          icon: icons.ApiOutlined,
          breadcrumbs: false,
         
        },
        {
          id: 'policy',
          title: 'Policy',
          type: 'item',
          url: `/mainlayout/${storedTheme}/policy`,
          icon: icons.PolicyOutlinedIcon,
          breadcrumbs: false
        }
      ]
    }
  ]
};

const webTitle = {
  id: 'group-dashboard',
  type: 'group',
  title: 'Theme Customisation',
  children: [
   
    {
      id: 'logo',
      title: ' Website logo & favicon',
      type: 'item',
      url: `/mainlayout/${storedTheme}/Logo`,
      icon: icons.DiamondIcon,
      breadcrumbs: false
    },
    {
      id: 'Footer',
      title: 'Footer',
      type: 'item',
      url: `/mainlayout/${storedTheme}/Footer`,
      icon: icons.DiamondIcon,
      breadcrumbs: false
    },
    {
      id: 'Themes',
      title: 'Themes',
      type: 'item',
      url: `/mainlayout/${storedTheme}/selectTheme`,
      icon: icons.DiamondIcon,
      breadcrumbs: false
    },
    // {
    //   id: 'theme',
    //   title: 'Website background',
    //   type: 'item',
    //   url: `/mainlayout/${storedTheme}/Theme`,
    //   icon: icons.ContrastOutlinedIcon,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'ButtonColor',
    //   title: ' Get Bonus Button',
    //   type: 'item',
    //   url: `/mainlayout/${storedTheme}/ButtonColor`,
    //   icon: icons.SmartButtonOutlinedIcon,
    //   breadcrumbs: false
    // }
  ]
};

export { SEO, webTitle };
