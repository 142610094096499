import React, { useState, useEffect } from 'react';
import { Card, Typography, Box,  Button, CardContent, Grid } from '@mui/material';
import axios from 'axios';
import { Link, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditStyledialog from './EditStyledialog';

const SelectTheme = () => {
  const { Themes } = useParams();
  const userid = localStorage.getItem('id');
  const [Templatedata, setTemplatedata] = useState();
  const [getTempdata, setTempdata] = useState();
  const [Thumbnailimg, setThumbnail] = useState();
  const [open, setOpen] = React.useState(false);
  const [Templatename, setTemplatename] = useState();

  const getTemplate = () => {
    axios
      .get('http://146.190.32.117/api/template')
      .then((res) => {
        setTemplatedata(res.data);
      })
      .catch((err) => console.log(err));
  };

  const PostSelectedTheme = (templatename) => {
    const postdata = {
      userid: userid,
      selectedTheme: templatename,
      Themename: Themes
    };
    axios
      .post('http://146.190.32.117/api/selectedTemp', postdata)
      .then((res) => {
        console.log(res.data);
        getTemplatedata();
        toast.success('Template Applied successfully!', {
          autoClose: 2000,
          position: 'bottom-left'
        });
      })
      .catch((err) => console.log(err));
  };
  const getTemplatedata = () => {
    axios
      .get('http://146.190.32.117/api/selectedTemp')
      .then((res) => {
        const filteredData = res.data.filter((item) => item.userid === userid && item.Themename === Themes);
        setTempdata(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const getTemplateimg = () => {
    axios
      .get('http://146.190.32.117/api/Thumbnail')
      .then((res) => {
        setThumbnail(res.data);
      })
      .catch((err) => console.log(err));
  };
  const handleDiaglog = (themename) => {
    setTemplatename(themename);
    setOpen(true);
  };
  useEffect(() => {
    getTemplate();
    getTemplatedata();
    getTemplateimg();
  }, []);
  return (
    <div>
      <Box>
        {open ? (
          <>
            <EditStyledialog open={open} setOpen={setOpen} Template={Templatename} />
          </>
        ) : (
          ''
        )}

        <Card variant="outlined" sx={{ padding: '20px' }}>
          <Typography variant="h6">Select Themes</Typography>
          <Grid container spacing={2}>
            {Thumbnailimg?.map((e, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1">Themes : {e.themename}</Typography>
                    <img src={e.thumbnail} alt={e.themename} width={'100%'} height={'250px'} />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '30px', gap: '15px', justifyContent: 'space-between' }}>
                      <Button
                        fullWidth
                        style={{
                          border: 'none',
                          padding: '10px 15px',
                          background: '#2828ef',
                          color: '#fff',
                          borderRadius: '10px',
                          transition: 'background-color 0.3s ease'
                        }}
                        onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgb(11 11 118)')}
                        onMouseLeave={(e) => (e.target.style.backgroundColor = '#2828ef')}
                        onClick={() => handleDiaglog(e.themename)}
                      >
                        Customize
                      </Button>
                      <Button
                        fullWidth
                        style={{
                          border: 'none',
                          padding: '10px 15px',
                          background: '#2828ef',
                          color: '#fff',
                          borderRadius: '10px',
                          transition: 'background-color 0.3s ease'
                        }}
                        // onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgb(11 11 118)')}
                        // onMouseLeave={(e) => (e.target.style.backgroundColor = '#2828ef')}
                      >
                        <Link to={`/Template/${e.themename}`} target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>
                          Preview
                        </Link>
                      </Button>
                      <Button
                        fullWidth
                        style={{
                          border: 'none',
                          padding: '10px 15px',
                          background: getTempdata?.selectedTheme === e.themename ? '#067a06e8' : '#2828ef',
                          color: '#fff',
                          borderRadius: '10px'
                        }}
                        onClick={() => PostSelectedTheme(e.themename)}
                      >
                        {getTempdata?.selectedTheme === e.themename ? ' Applied' : 'Apply'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default SelectTheme;
