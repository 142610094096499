import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ColorPicker from "react-best-gradient-color-picker";
import {  useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  CardContent,TextField,
  Card,
  Grid,
  FormHelperText,
  Autocomplete,
  Box,
  Select,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import '../Home/Home.css';

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default function EditStyledialog({ open, setOpen, Template }) {
  const handleClose = () => {
    setOpen(false);
  };

  const { Themes } = useParams();
  const [colorType, setColorType] = useState("solid");
  const [color, setColor] = useState();
  const [BackgroundColor, setBackgroundcolor] = useState();

  const fonts = [
    "Roboto",
    "Roboto Slab",
    "Sixtyfour",
    "Poppins",
    "Protest Riot",
    "Open Sans",
    "Montserrat",
    "Nunito",
    "Nunito Sans",
    "Public Sans",
  ];
  const [fontWeights, setFontWeights] = useState(["100", "200", "300","400","500","600", "700", "800","900"]);
  const [tablehead, setTableHead] = useState({
    backgroundcolor: "",
    textcolor: "",
    fontFamily: "",
    fontsize: "",
    fontWeight: "300",
    topleftradius: "",
    toprightradius: "",
    bottomrightradius: "",
    bottomleftradius: "",
    bordersize: "",
    borderstyle: "solid",
    bordercolor: "",
  });
  const [gamelistStyle, setgamelistStyle] = useState({
    Template: "",
    topleftradius: "",
    toprightradius: "",
    bottomrightradius: "",
    bottomleftradius: "",
    bordersize: "",
    borderstyle: "",
    bordercolor: "",
    BackgroundcolorMv:'',
    textcolormv : '',
    textcolor:'',
    Backgroundcolor: ''
  });

  const [TitleStyledata, setTitleStyledata] = useState({
    textcolor: "",
    fontFamily: "",
    fontsize: "",
    fontWeight: "300",
    textcolorMv: "",
    fontFamilyMv: "",
    fontsizeMv: "",
    fontWeightMv: "300",
  });
  const [DescriptionStyledata, setDescriptionStyledata] = useState({
    textcolor: "",
    fontFamily: "",
    fontsize: "",
    fontWeight: "300",
  });
  const [FeatureStyledata, setFeatureStyledata] = useState({
    textcolor: "",
    fontFamily: "",
    fontsize: "",
    fontWeight: "300",
  });
  const [ButtonStyledata, setButtonStyledata] = useState({
    fontFamily: "",
    fontsize: "",
    textcolor: "",
    fontWeight: "",
    Template: "",
    backgroundcolor: "",
    topleftradius: "",
    toprightradius: "",
    bottomrightradius: "",
    bottomleftradius: "",
    bordersize: "",
    borderstyle: "",
    bordercolor: "",
  });
  const [Headerbackground, setHeaderbackground] = useState();
  const [HeaderStyledata, setHeaderStyledata] = useState();
  const [datestyleid, setDatestyleid] = useState();
  const [TableLogo, setTableLogo] = useState();
  const [screenView, setView] = useState('desktop');
  const [screenViewList, setViewList] = useState('desktop');
  const [Ratingcolor, setRatingcolor] = useState()
  const userid = localStorage.getItem("id");

// Rating style

const handleChangeratingColor = (e)=>{
  const { name, value } = e.target;
  setRatingcolor({ ...Ratingcolor, [name]: value });
}

const getRatingstyle = () => {
  axios
    .get("http://146.190.32.117/api/RatingStyle")
    .then((res) => {
      const filteredData = res.data.filter(
        (item) => item.Template === Template
      );
      setRatingcolor(filteredData[0]);
    })
    .catch((err) => console.log(err));
};
const [ratingid, setratingid] = useState();
const getRatingstyleCustom = () => {
  axios
    .get("http://146.190.32.117/api/RatingStyleCustom")
    .then((res) => {
      const filteredData = res.data.filter(
        (item) => item.Template === Template && item.Themename === Themes && item.userid === userid);
        if (filteredData.length <= 0) {
          getRatingstyle();
        }
      setRatingcolor(filteredData[0]);
      setratingid(filteredData[0])
    })
    .catch((err) => console.log(err));
};
const handleSubmitRatingcolor = () => {
  axios
    .post("http://146.190.32.117/api/RatingStyleCustom", {...Ratingcolor, Template: Template, Themename: Themes,userid: userid}).then((res) => {
      toast.success("Rating Style Added Successfully", {
        autoClose: 2000,
        position: "bottom-left",
      });
      getRatingstyleCustom();
    })
    .catch((err) => console.log(err));
};
const UpdateRatingColor = (Id) => {
  axios
    .put(
      `http://146.190.32.117/api/RatingStyleCustom/${Id}`,{...Ratingcolor} )
    .then((res) => {
      console.log(res.data);
      toast.success("Rating Style Updated Successfully", {
        autoClose: 2000,
        position: "bottom-left",
      });
      getRatingstyleCustom()
    })
    .catch((err) => console.log(err));
};

  // Button Style
  const handleChangeButton = (e) => {
    const { name, value } = e.target;
    setButtonStyledata({ ...ButtonStyledata, [name]: value });
  };
  const getButtonstyle = () => {
    axios
      .get("http://146.190.32.117/api/ButtonStyle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setButtonStyledata(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const [btnId, setBtnId] = useState();
  const getCustomButton = () => {
    axios
      .get("http://146.190.32.117/api/CustomButton")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) =>
            item.Template === Template &&
            item.Themename === Themes &&
            item.userid === userid
        );
        setButtonStyledata(filteredData[0]);
        if (filteredData.length <= 0) {
          getButtonstyle();
        }
        setBtnId(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const handleSubmitButton = () => {
    axios
      .post("http://146.190.32.117/api/CustomButton", {
        ...ButtonStyledata,
        Template: Template,
        Themename: Themes,
        userid: userid,
      })
      .then((res) => {
        toast.success("Button Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomButton();
      })
      .catch((err) => console.log(err));
  };
  const handleUpdateButton = (Id) => {
    axios
      .put(`http://146.190.32.117/api/CustomButton/${Id}`, ButtonStyledata)
      .then((res) => {
        console.log(res.data);
        toast.success("Button Style Updated Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomButton();
      })
      .catch((err) => console.log(err));
  };
  // Website Feature
  const handleChangeFeature = (e) => {
    const { name, value } = e.target;
    setFeatureStyledata({ ...FeatureStyledata, [name]: value });
  };

  const getFeaturedata = () => {
    axios
      .get("http://146.190.32.117/api/Featurestyle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setFeatureStyledata(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const [featureId, setFeatureId] = useState();
  const getCustomFeature = () => {
    axios
      .get("http://146.190.32.117/api/CustomFeatures")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) =>
            item.Template === Template &&
            item.Themename === Themes &&
            item.userid === userid
        );
        setFeatureStyledata(filteredData[0]);
        if (filteredData.length <= 0) {
          getFeaturedata();
        }
        setFeatureId(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitFeature = () => {
    axios
      .post("http://146.190.32.117/api/CustomFeatures", {
        ...FeatureStyledata,
        Template: Template,
        Themename: Themes,
        userid: userid,
      })
      .then((res) => {
        toast.success("Feature Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomFeature();
      })
      .catch((err) => console.log(err));
  };
  const handleupdateFeature = (ID) => {
    axios
      .put(
        `http://146.190.32.117/api/CustomFeatures/${ID}`,FeatureStyledata )
      .then((res) => {
        console.log(res.data);
        toast.success("Feature Style Updated Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomFeature();
      })
      .catch((err) => console.log(err));
  };
  // Website Description

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;
    setDescriptionStyledata({ ...DescriptionStyledata, [name]: value });
  };
  const getDescriptionstyle = () => {
    axios
      .get("http://146.190.32.117/api/Descriptionstyle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setDescriptionStyledata(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const [DescriptionId, setDescriptionId] = useState();
  const getCustomDescription = () => {
    axios
      .get("http://146.190.32.117/api/Customdescription")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template && item.Themename == Themes
        );
        setDescriptionStyledata(filteredData[0]);

        if (filteredData.length <= 0) {
          getDescriptionstyle();
        }
        setDescriptionId(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitDescription = () => {
    axios
      .post("http://146.190.32.117/api/Customdescription", {
        ...DescriptionStyledata,
        Template: Template,
        Themename: Themes,
        userid: userid,
      })
      .then((res) => {
        console.log(res.data);
        toast.success("Description Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomDescription();
      })
      .catch((err) => console.log(err));
  };
  const handleUpdateDescription = (Id) => {
    axios
      .put(`http://146.190.32.117/api/Customdescription/${Id}`, DescriptionStyledata)
      .then((res) => {
        console.log(res.data);
        toast.success("Description Style Update Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomDescription();
      })
      .catch((err) => console.log(err));
  };
  // Website Title
  const handleChangeTitle = (e) => {
    const { name, value } = e.target;
    setTitleStyledata({ ...TitleStyledata, [name]: value });
  };
  const getTitleStyle = () => {
    axios
      .get("http://146.190.32.117/api/Titlestyle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setTitleStyledata(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const [titleId, settitleId] = useState();
  const getCustomizeTitle = () => {
    axios
      .get("http://146.190.32.117/api/CustomTitle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template && item.Themename === Themes
        );
        setTitleStyledata(filteredData[0]);
        if (filteredData.length <= 0) {
          getTitleStyle();
        }
        settitleId(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const handleSubmitTitle = () => {
    axios
      .post("http://146.190.32.117/api/CustomTitle", {
        ...TitleStyledata,
        Template: Template,
        userid: userid,
        Themename: Themes,
      })
      .then((res) => {
        console.log(res.data);
        toast.success("Title Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomizeTitle();
      })
      .catch((err) => console.log(err));
  };
  const handleUpdateTitle = (Id) => {
    axios
      .put(`http://146.190.32.117/api/CustomTitle/${Id}`,{...TitleStyledata})
      .then((res) => {
        console.log(res.data);
        toast.success("Title Style Update Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomizeTitle();
      })
      .catch((err) => console.log(err));
  };

  // Table Heading
  const getTablehead = () => {
    axios
      .get("http://146.190.32.117/api/TableHeading")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setTableHead(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const [headid, setHeadid] = useState();
  const getCutomizehead = () => {
    axios
      .get("http://146.190.32.117/api/Tablehead")
      .then((res) => {
        const filteredData = res.data.filter((item) => item.Template === Template && item.Themename === Themes && item.userid === userid );
        setTableHead(filteredData[0]);

        if (filteredData.length <= 0) {
          getTablehead();
        }
        setHeadid(filteredData[0]?._id);
      })
      .catch((err) => console.log(err));
  };
  const handleSubmittablehead = () => {
    axios
      .post("http://146.190.32.117/api/Tablehead", {
        ...tablehead,
        Template: Template,
        userid: userid,
        Themename: Themes,
      })
      .then((res) => {
        getCutomizehead();
        toast.success("Table Heading Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
      })
      .catch((err) => console.log(err));
  };

  const handleChangehead = (e) => {
    const { name, value } = e.target;
    setTableHead({ ...tablehead, [name]: value });
  };
  const handleupdatetablehead = (Id) => {
    axios
      .put(`http://146.190.32.117/api/Tablehead/${Id}`, tablehead)
      .then((res) => {
        console.log(res.data);
        toast.success("Table Heading Style Updated Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCutomizehead();
      })
      .catch((err) => console.log(err));
  };

  // Logo background
  const handleChangeLogo = (e) => {
    const { name, value } = e.target;
    setTableLogo({ ...TableLogo, [name]: value });
  };
  const getTableLogo = () => {
    axios
      .get("http://146.190.32.117/api/TableLogo")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setTableLogo(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const [Logoid, setLogoid] = useState();
  const GetCustomTablelogo = () => {
    axios
      .get("http://146.190.32.117/api/CustomTableLogo")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) =>
            item.Template === Template &&
            item.Themename == Themes &&
            item.userid == userid
        );
        if (filteredData.length <= 0) {
          getTableLogo();
        }
        setTableLogo(filteredData[0]);
        setLogoid(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const handleSubmitTableLogo = () => {
    axios
      .post("http://146.190.32.117/api/CustomTableLogo", {
        ...TableLogo,
        Template: Template,
        Themename: Themes,
        userid: userid,
      })
      .then((res) => {
        toast.success("Table Logo Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        GetCustomTablelogo();
      })
      .catch((err) => console.log(err));
  };
  const UpdateTableLogodata = (Id) => {
    axios
      .put(`http://146.190.32.117/api/CustomTableLogo/${Id}`, { ...TableLogo })
      .then((res) => {
        console.log(res.data);
        toast.success("Table Sr. Style Update Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        GetCustomTablelogo();
      })
      .catch((err) => console.log(err));
  };
  // Update date style
  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    setHeaderStyledata({ ...HeaderStyledata, [name]: value });
  };
  const GetUpdateDatestyle = () => {
    axios
      .get("http://146.190.32.117/api/Updatedate")
      .then((res) => {
        console.log(res.data);
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setHeaderStyledata(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const getCustomDateStyle = () => {
    axios
      .get("http://146.190.32.117/api/CustomUpdatedate")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) =>
            item.Template === Template &&
            item.Themename == Themes &&
            item.userid == userid
        );
        if (filteredData.length <= 0) {
          GetUpdateDatestyle();
        }
        setHeaderStyledata(filteredData[0]);
        setDatestyleid(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const handledateStylesubmit = () => {
    axios
      .post("http://146.190.32.117/api/CustomUpdatedate", {
        ...HeaderStyledata,
        Template: Template,
        Themename: Themes,
        userid: userid,
      })
      .then((res) => {
        toast.success("Date Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomDateStyle();
      })
      .catch((err) => console.log(err));
  };
  const handleDatestyleUpdate = (Id) => {
    axios
      .put(`http://146.190.32.117/api/CustomUpdatedate/${Id}`, {
        ...HeaderStyledata,
      })
      .then((res) => {
        toast.success("Date Style Update Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomDateStyle();
      })
      .catch((err) => console.log(err));
  };

  //  Background color
  const getImagedata = () => {
    axios
      .get("http://146.190.32.117/api/BackImage")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );

        setHeaderbackground(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const [imgId, setImgId] = useState();
  const getCustombackImage = ()=>{
    axios.get('http://146.190.32.117/api/CustomBackimage').then((res)=>{
      const filteredData = res.data.filter( (item) =>item.Template === Template && item.Themename == Themes && item.userid == userid);
      setHeaderbackground(filteredData[0]);
      setImgId(filteredData[0]?._id)
      if (filteredData.length <= 0) {
        getImagedata();
      }
    }).catch((err)=> console.log(err))
  }
  const handleSubmitImage = () => {
    const postdata = {
      Template: Template,
      Themename: Themes,
      userid: userid,
      ...Headerbackground,
    };
    axios
      .post("http://146.190.32.117/api/CustomBackimage", postdata)
      .then((res) => {
        toast.success("Background-Image Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustombackImage();
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateImage = (Id) => {
    axios
      .put(`http://146.190.32.117/api/CustomBackimage/${Id}`, {
        ...Headerbackground,
      })
      .then((res) => {
        toast.success("Update data Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustombackImage();
      })
      .catch((err) => console.log(err));
  };
  const getColordata = () => {
    axios
      .get("http://146.190.32.117/api/Backcolor")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setColor(filteredData[0]?.backgroundcolor);
        // setHeaderbackground(filteredData[0]);
      })
      .catch((err) => console.log(err));
  };
  const getCustomizebackground = () => {
    axios
      .get("http://146.190.32.117/api/backgroundstyle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) =>
            item.Template === Template &&
            item.Themename == Themes &&
            item.userid == userid
        );

        if (filteredData.length <= 0) {
          getColordata();
        }
        setBackgroundcolor(filteredData[0]);
        // setHeaderbackground(filteredData[0]);
        setColor(filteredData[0]?.backgroundcolor);
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = () => {
    const postdata = {
      backgroundcolor: color,
      Template: Template,
      Themename: Themes,
      userid: userid,
      ...Headerbackground,
    };
    axios
      .post("http://146.190.32.117/api/backgroundstyle", postdata)
      .then((res) => {
        toast.success("Background-Color Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        setColorType("solid");
        getCustomizebackground();
      })
      .catch((err) => console.log(err));
  };

  const handleUpdate = (Id) => {
    axios
      .put(`http://146.190.32.117/api/backgroundstyle/${Id}`, {
        ...Headerbackground,
        backgroundcolor: color,
      })
      .then((res) => {
        toast.success("Update data Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomizebackground();
        setColorType("solid");
      })
      .catch((err) => console.log(err));
  };
  const deleteDesktopImage = () => {
    setHeaderbackground({ ...Headerbackground, desktopimg: "" });
  };

  // Function to delete mobile image
 
  // game list style
  const handleChangegamelist = (e) => {
    const { name, value } = e.target;
    setgamelistStyle({ ...gamelistStyle, [name]: value });
  };
  const getGamelistdata = () => {
    axios
      .get("http://146.190.32.117/api/Gameliststyle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template
        );
        setgamelistStyle(filteredData[0]);
        
      })
      .catch((err) => console.log(err));
  };
  const [gamelistid, setgamelistid] = useState();
  const getCustomGamelistdata = () => {
    axios
      .get("http://146.190.32.117/api/CustomGameliststyle")
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.Template === Template && item.Theme === Themes
        );
        setgamelistStyle(filteredData[0]);
        if (filteredData.length <= 0) {
          getGamelistdata();
        }
        setgamelistid(filteredData[0]?._id);
      })
      .catch((err) => console.log(err));
  };
  const handleSubmitgamelist = () => {
    axios
      .post("http://146.190.32.117/api/CustomGameliststyle", {
        ...gamelistStyle,
        Template: Template,
        Theme: Themes,
        userid: userid,
      })
      .then((res) => {
        toast.success("Gamelist Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomGamelistdata();
      })
      .catch((err) => console.log(err));
  };
  const Updategamelistdata = (Id) => {
    axios
      .put(`http://146.190.32.117/api/CustomGameliststyle/${Id}`, gamelistStyle)
      .then((res) => {
        console.log(res.data);
        toast.success("Gamelist Style Added Successfully", {
          autoClose: 2000,
          position: "bottom-left",
        });
        getCustomGamelistdata();
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        getCustomizebackground(),
        getCustomDateStyle(),
        getCutomizehead(),
        GetCustomTablelogo(),
        getCustomizeTitle(),
        getCustomDescription(),
        getCustomFeature(),
        getCustomButton(),
        getCustomGamelistdata(),
        getRatingstyleCustom(),
        getCustombackImage(),
      ]);
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Customize Template</Typography>
          <IconButton onClick={handleClose}>
            {" "}
            <CloseIcon />{" "}
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box>
            {/* Background Color */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: "600" }}
              >
                Select Background
              </AccordionSummary>
              <AccordionDetails>
                <Card variant="outlined" title="Template">
                  <CardContent sx={{ padding: "30px" }}>
                    <FormHelperText
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Select Background Color
                    </FormHelperText>
                    <RadioGroup
                      aria-label="color-type"
                      name="color-type"
                      value={colorType}
                      onChange={(e) => setColorType(e.target.value)}
                      row
                    >
                      <FormControlLabel
                        value="solid"
                        control={<Radio />}
                        label="Solid"
                      />
                      <FormControlLabel
                        value="gradient"
                        control={<Radio />}
                        label="Gradient"
                      />
                    </RadioGroup>

                    {colorType === "solid" ? (
                      <Grid container spacing={2}>
                        <Grid item xs={0.8}>
                          <input
                            type="color"
                            name="color"
                            fullWidth
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            style={{ height: "52px", cursor:'pointer' }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            type="text"
                            name="color"
                            focused
                            fullWidth
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            label="Template Background Color"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ColorPicker value={color} onChange={setColor} />
                        </Grid>
                        <Grid item xs={6}>
                          <Card
                            variant="outlined"
                            sx={{ background: color, height: "300px" }}
                          ></Card>
                        </Grid>
                      </Grid>
                    )}

                    <Box sx={{ mt: "25px" }}>
                      {BackgroundColor?._id ? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() => handleUpdate(BackgroundColor?._id)}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
                <Card variant="outlined" sx={{ mt: "25px" }}>
                  <CardContent>
                    <FormHelperText
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "black",
                        mb: "10px",
                      }}
                    >
                      Select Header Image
                    </FormHelperText>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          type="file"
                          name="desktopimg"
                          focused
                          fullWidth
                          //  value={Headerbackground?.desktopimg}
                          onChange={async (event) => {
                            const { files } = event.target;
                            const file = files[0];
                            const base64 = await convertToBase64(file);
                            setHeaderbackground({
                              ...Headerbackground,
                              desktopimg: base64,
                            });
                          }}
                          label="Header Image"
                        />
                        <FormHelperText sx={{ color: "red" }}>
                          Size must be 1163×703
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={8}>
                        {Headerbackground?.desktopimg ? (
                          <>
                            <Tooltip title="Delete">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  display: "flex",
                                  justifyContent: "end",
                                  ml: "23rem",
                                  cursor: "pointer",
                                  color: "#fff",
                                }}
                                onClick={deleteDesktopImage}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>

                            <img
                              src={Headerbackground?.desktopimg}
                              height={"200"}
                              width={"400"}
                              style={{ marginTop: "5px" }}
                            />
                          </>
                        ) : (
                          "Image Not Uploaded"
                        )}
                      </Grid>
                      {/* <Grid item xs={4}>
                        <TextField
                          type="file"
                          name="Mobileimg"
                          focused
                          fullWidth
                          // value={Headerbackground?.mobileimg}
                          onChange={async (event) => {
                            const { files } = event.target;
                            const file = files[0];
                            const base64 = await convertToBase64(file);
                            setHeaderbackground({
                              ...Headerbackground,
                              mobileimg: base64,
                            });
                          }}
                          label="Mobile View Image"
                        />
                        <FormHelperText sx={{ color: "red" }}>
                          Size must be 360×192
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={8}>
                        {Headerbackground?.mobileimg ? (
                          <>
                            <Tooltip title="Delete">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  display: "flex",
                                  justifyContent: "end",
                                  ml: "19rem",
                                  cursor: "pointer",
                                  color: "#fff",
                                }}
                                onClick={deleteMobileImage}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                            <img
                              src={Headerbackground?.mobileimg}
                              height={200}
                            />
                          </>
                        ) : (
                          "Image Not Uploaded"
                        )}
                      </Grid> */}
                    </Grid>
                    <Box sx={{ mt: "25px" }}>
                      {imgId? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() => handleUpdateImage(Headerbackground?._id)}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handleSubmitImage()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>

            {/* Website Title */}
            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: "600" }}
              >
                Website Title
              </AccordionSummary>
              <AccordionDetails>
              <FormHelperText sx={{ fontSize: "16px", fontWeight: "600", color: "black" }} > Select View </FormHelperText>
                <RadioGroup
                  aria-label="View-type"
                  name="View-type"
                  value={screenView}
                  onChange={(e) => setView(e.target.value)}
                  row
                >
                   <FormControlLabel
                    value="desktop"
                    control={<Radio />}
                    label="Desktop View"
                  />
                  <FormControlLabel
                    value="mobile"
                    control={<Radio />}
                    label="Mobile View"
                  />
                 
                </RadioGroup>
                {screenView === 'mobile' && (
                   <Card variant="outlined" sx={{ mt: "20px" }}>
                   <CardContent sx={{ padding: "30px" }}>
                     <FormHelperText
                       sx={{
                         fontWeight: "600",
                         fontSize: "16px",
                         mb: "10px",
                         color: "black",
                       }}
                     >
                       Website Title
                     </FormHelperText>
                     <Grid container spacing={2}>
                       <Grid item xs={0.5}>
                         <input
                           type="Color"
                           fullWidth
                           name="textcolorMv"
                           value={TitleStyledata?.textcolorMv}
                           onChange={handleChangeTitle}
                           style={{ height: "52px", cursor:'pointer' }}
                         />
                       </Grid>
                       <Grid item xs={1.5}>
                         <TextField
                           type="text"
                           focused
                           fullWidth
                           label="Text Color"
                           name="textcolorMv"
                           value={TitleStyledata?.textcolorMv}
                           onChange={handleChangeTitle}
                         />
                       </Grid>
     
                       <Grid item xs={3}>
                         {/* <FormHelperText className="label">Select Font-Family</FormHelperText> */}
                         <Autocomplete
                           value={TitleStyledata?.fontFamilyMv || null}
                           onChange={(event, newValue) =>
                             setTitleStyledata({
                               ...TitleStyledata,
                               fontFamilyMv: newValue,
                             })
                           }
                           options={fonts}
                           renderInput={(params) => (
                             <TextField {...params} label="Select Font Family" />
                           )}
                           getOptionLabel={(font) => font}
                           isOptionEqualToValue={(option, value) => option === value}
                           renderOption={(props, font) => (
                             <li {...props} style={{ fontFamily: font }}>
                               {font}
                             </li>
                           )}
                         />
                       </Grid>
                       <Grid item xs={3}>
                         <TextField
                           type="number"
                           fullWidth
                           name="fontsizeMv"
                           value={TitleStyledata?.fontsizeMv}
                           label="Font Size"
                           variant="outlined"
                           focused
                           onChange={handleChangeTitle}
                         />
                       </Grid>
     
                       <Grid item xs={3}>
                         <Autocomplete
                           fullWidth
                           options={fontWeights}
                           getOptionLabel={(option) => option}
                           value={TitleStyledata?.fontWeightMv || null}
                           onChange={(event, newValue) => {
                             setTitleStyledata({
                               ...TitleStyledata,
                               fontWeightMv: newValue,
                             });
                           }}
                           renderInput={(params) => (
                             <TextField
                               {...params}
                               label="Font Weight"
                               variant="outlined"
                             />
                           )}
                         />
                       </Grid>
                     </Grid>
                     <Box sx={{ mt: "25px" }}>
                       {titleId?._id ? (
                         <Button
                           variant="contained"
                           sx={{ padding: "5px 20px" }}
                           onClick={() => handleUpdateTitle(TitleStyledata?._id)}
                         >
                           Update
                         </Button>
                       ) : (
                         <Button
                           variant="contained"
                           sx={{ padding: "5px 20px", mr: "20px" }}
                           onClick={() => handleSubmitTitle()}
                         >
                           Save
                         </Button>
                       )}
                     </Box>
                   </CardContent>
                 </Card>
                )}
                {screenView === 'desktop' && (
                     <Card variant="outlined" sx={{ mt: "20px" }}>
                     <CardContent sx={{ padding: "30px" }}>
                       <FormHelperText
                         sx={{
                           fontWeight: "600",
                           fontSize: "16px",
                           mb: "10px",
                           color: "black",
                         }}
                       >
                         Website Title
                       </FormHelperText>
                       <Grid container spacing={2}>
                         <Grid item xs={0.5}>
                           <input
                             type="Color"
                             fullWidth
                             name="textcolor"
                             value={TitleStyledata?.textcolor}
                             onChange={handleChangeTitle}
                             style={{ height: "52px", cursor:'pointer' }}
                           />
                         </Grid>
                         <Grid item xs={1.5}>
                           <TextField
                             type="text"
                             focused
                             fullWidth
                             label="Text Color"
                             name="textcolor"
                             value={TitleStyledata?.textcolor}
                             onChange={handleChangeTitle}
                           />
                         </Grid>
   
                         <Grid item xs={3}>
                           <Autocomplete
                             value={TitleStyledata?.fontFamily || null}
                             onChange={(event, newValue) =>
                               setTitleStyledata({
                                 ...TitleStyledata,
                                 fontFamily: newValue,
                               })
                             }
                             options={fonts}
                             renderInput={(params) => (
                               <TextField {...params} label="Select Font Family" />
                             )}
                             getOptionLabel={(font) => font}
                             isOptionEqualToValue={(option, value) =>
                               option === value
                             }
                             renderOption={(props, font) => (
                               <li {...props} style={{ fontFamily: font }}>
                                 {font}
                               </li>
                             )}
                           />
                         </Grid>
                         <Grid item xs={3}>
                           <TextField
                             type="number"
                             fullWidth
                             name="fontsize"
                             value={TitleStyledata?.fontsize}
                             label="Font Size"
                             variant="outlined"
                             focused
                             onChange={handleChangeTitle}
                           />
                         </Grid>
   
                         <Grid item xs={3}>
                           <Autocomplete
                             fullWidth
                             options={fontWeights}
                             getOptionLabel={(option) => option}
                             value={TitleStyledata?.fontWeight || null}
                             onChange={(event, newValue) => {
                               setTitleStyledata({
                                 ...TitleStyledata,
                                 fontWeight: newValue,
                               });
                             }}
                             renderInput={(params) => (
                               <TextField
                                 {...params}
                                 label="Font Weight"
                                 variant="outlined"
                               />
                             )}
                           />
                         </Grid>
                       </Grid>
                       <Box sx={{ mt: "25px" }}>
                         {titleId?._id ? (
                           <Button
                             variant="contained"
                             sx={{ padding: "5px 20px" }}
                             onClick={() => handleUpdateTitle(TitleStyledata?._id)}
                           >
                             Update
                           </Button>
                         ) : (
                           <Button
                             variant="contained"
                             sx={{ padding: "5px 20px", mr: "20px" }}
                             onClick={() => handleSubmitTitle()}
                           >
                             Save
                           </Button>
                         )}
                       </Box>
                     </CardContent>
                   </Card>
                )}
             
              </AccordionDetails>
            </Accordion>
            {/* Website Description */}
            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: "600" }}
              >
                Website Description
              </AccordionSummary>
              <AccordionDetails>
                <Card variant="outlined" sx={{ mt: "20px" }}>
                  <CardContent sx={{ padding: "30px" }}>
                    <FormHelperText
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "black",
                        mb: "10px",
                      }}
                    >
                      Website Description
                    </FormHelperText>
                    <Grid container spacing={2}>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="textcolor"
                          value={DescriptionStyledata?.textcolor}
                          onChange={handleChangeDescription}
                          style={{ height: "52px", cursor:'pointer' }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          fullWidth
                          label="Text Color"
                          name="textcolor"
                          value={DescriptionStyledata?.textcolor}
                          onChange={handleChangeDescription}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        {/* <FormHelperText className="label">Select Font-Family</FormHelperText> */}
                        <Autocomplete
                          value={DescriptionStyledata?.fontFamily || null}
                          onChange={(event, newValue) =>
                            setDescriptionStyledata({
                              ...DescriptionStyledata,
                              fontFamily: newValue,
                            })
                          }
                          options={fonts}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Font Family" />
                          )}
                          getOptionLabel={(font) => font}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderOption={(props, font) => (
                            <li {...props} style={{ fontFamily: font }}>
                              {font}
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          type="number"
                          fullWidth
                          name="fontsize"
                          value={DescriptionStyledata?.fontsize}
                          label="Font Size"
                          variant="outlined"
                          focused
                          onChange={handleChangeDescription}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Autocomplete
                          fullWidth
                          options={fontWeights}
                          getOptionLabel={(option) => option}
                          value={DescriptionStyledata?.fontWeight || null}
                          onChange={(event, newValue) => {
                            setDescriptionStyledata({
                              ...DescriptionStyledata,
                              fontWeight: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Font Weight"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: "25px" }}>
                      {DescriptionId?._id ? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() =>
                            handleUpdateDescription(DescriptionStyledata?._id)
                          }
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handleSubmitDescription()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>

            {/* Update To date */}
            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel4-header"
                sx={{ fontWeight: "600" }}
              >
                Updated to date style
              </AccordionSummary>
              <AccordionDetails>
                <Card variant="outlined" sx={{ mt: "20px" }}>
                  <CardContent sx={{ padding: "30px" }}>
                    <FormHelperText
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        mb: "10px",
                        color: "black",
                      }}
                    >
                      Updated to date style
                    </FormHelperText>
                    <Grid container spacing={2}>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="textcolor"
                          value={HeaderStyledata?.textcolor}
                          onChange={handleChangeDate}
                          style={{ height: "52px", cursor:'pointer' }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          focused
                          fullWidth
                          label="Text Color"
                          name="textcolor"
                          value={HeaderStyledata?.textcolor}
                          onChange={handleChangeDate}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Autocomplete
                          value={HeaderStyledata?.fontFamily || null}
                          onChange={(event, newValue) =>
                            setHeaderStyledata({
                              ...HeaderStyledata,
                              fontFamily: newValue,
                            })
                          }
                          options={fonts}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Font Family" />
                          )}
                          getOptionLabel={(font) => font}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderOption={(props, font) => (
                            <li {...props} style={{ fontFamily: font }}>
                              {font}
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          type="number"
                          fullWidth
                          name="fontsize"
                          value={HeaderStyledata?.fontsize}
                          label="Font Size"
                          variant="outlined"
                          focused
                          onChange={handleChangeDate}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Autocomplete
                          fullWidth
                          options={fontWeights}
                          getOptionLabel={(option) => option}
                          value={HeaderStyledata?.fontWeight || null}
                          onChange={(event, newValue) => {
                            setHeaderStyledata({
                              ...HeaderStyledata,
                              fontWeight: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Font Weight"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: "25px" }}>
                      {datestyleid?._id ? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() =>
                            handleDatestyleUpdate(HeaderStyledata?._id)
                          }
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handledateStylesubmit()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>

            {/* Website Feature */}
            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: "600" }}
              >
                Website Feature
              </AccordionSummary>
              <AccordionDetails>
                <Card variant="outlined" sx={{ mt: "20px" }}>
                  <CardContent sx={{ padding: "30px" }}>
                    <FormHelperText
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        mb: "10px",
                        color: "black",
                      }}
                    >
                      Website Feature
                    </FormHelperText>
                    <Grid container spacing={2}>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="textcolor"
                          value={FeatureStyledata?.textcolor}
                          onChange={handleChangeFeature}
                          style={{ height: "52px", cursor:'pointer' }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          fullWidth
                          label="Text Color"
                          name="textcolor"
                          value={FeatureStyledata?.textcolor}
                          onChange={handleChangeFeature}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          value={FeatureStyledata?.fontFamily || null}
                          onChange={(event, newValue) =>
                            setFeatureStyledata({
                              ...FeatureStyledata,
                              fontFamily: newValue,
                            })
                          }
                          options={fonts}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Font Family" />
                          )}
                          getOptionLabel={(font) => font}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderOption={(props, font) => (
                            <li {...props} style={{ fontFamily: font }}>
                              {font}
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          type="number"
                          fullWidth
                          name="fontsize"
                          value={FeatureStyledata?.fontsize}
                          label="Font Size"
                          variant="outlined"
                          focused
                          onChange={handleChangeFeature}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          fullWidth
                          options={fontWeights}
                          getOptionLabel={(option) => option}
                          value={FeatureStyledata?.fontWeight || null}
                          onChange={(event, newValue) => {
                            setFeatureStyledata({
                              ...FeatureStyledata,
                              fontWeight: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Font Weight"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: "25px" }}>
                      {featureId?._id ? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() =>
                            handleupdateFeature(FeatureStyledata?._id)
                          }
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handleSubmitFeature()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>

            {/* Table Head  */}

            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: "600" }}
              >
                Table Head
              </AccordionSummary>
              <div>
            <div
              className="content Text-container"
              style={{ marginLeft: "10px" }}
            >
              <div className="ppc-dl col-md-12">
                <div
                  className="table-headers casino row-head"
                  style={{
                    backgroundColor: tablehead?.backgroundcolor,
                    fontSize: `${tablehead?.fontsize}px`,
                    fontWeight: tablehead?.fontWeight,
                    fontFamily: tablehead?.fontFamily,
                    color: tablehead?.textcolor,
                    border: `${tablehead?.bordersize}px ${
                      tablehead?.borderstyle || "select"
                    } ${tablehead?.bordercolor}`,
                    borderTopLeftRadius: `${tablehead?.topleftradius}px`,
                    borderTopRightRadius: `${tablehead?.toprightradius}px `,
                    borderBottomLeftRadius: `${tablehead?.bottomleftradius}px`,
                    borderBottomRightRadius: `${tablehead?.bottomrightradius}px`,
                  }}
                >
                  <div className="cell logo">Sites </div>
                  <div className="cell bonus">Bonus</div>
                  <div className="cell ratinghead">Rating</div>
                  <div className="cell features  d-lg-flex">Rating</div>
                  <div className="cell get-bonus">Play Now</div>
                </div>
                <div className="dl-list casino row">
                  <div
                    className="table-row"
                    data-brand-position="“9”"
                    data-brand-title=" Fantasy"
                    style={{
                      backgroundColor: gamelistStyle?.Backgroundcolor,
                      padding: "0",
                      borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                      borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      borderTopRightRadius: `${gamelistStyle?.toprightradius}px`,
                      borderBottomRightRadius: `${gamelistStyle?.bottomrightradius}px`,
                      border: `${gamelistStyle?.bordersize}px ${gamelistStyle?.borderstyle} ${gamelistStyle?.bordercolor}`,
                    }}
                  >
                    {/* TableLogo?.backgroundcolor */}
                    <div
                      className="cell-logo"
                      style={{
                        backgroundColor: TableLogo?.backgroundcolor,
                        borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                        borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      }}
                    >
                      <a
                        className="brand-name-logo-link"
                        href={""}
                        rel="nofollow"
                        target="_blank"
                      >
                        <img
                          className="lazy"
                          alt=" Fantasy"
                          src="https://ik.imagekit.io/navfnqmjg/dream11.svg?updatedAt=1710412933809"
                        />
                      </a>
                      <div className="white-box" />
                    </div>
                    {/* gamelistStyle?.textcolor */}
                    <div className="cell-bonus " style={{ width: "28%" }}>
                      <p
                        style={{
                          textAlign: "center",
                          color: gamelistStyle?.textcolor,
                          fontSize: "14px",
                        }}
                      >
                        PLAY FANTASY CRICKET & WIN
                        <br />
                        <b
                          className="big-bonus1"
                          style={{ color: gamelistStyle?.textcolor }}
                        >
                          100000
                          <br />
                        </b>
                        Lightning Fast Withdrawals
                      </p>
                      <div className="legal">
                        <a href={""} target="_blank">
                          <p
                            className="link-upi"
                            style={{
                              color: gamelistStyle?.textcolor,
                              fontSize: "11px",
                            }}
                          >
                            UPI &amp; Netbanking Accepted
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="cell-rating">
                      <div className="score-stars">
                        <img
                          className="lazy"
                          alt="Play Fantasy"
                          src={
                            "https://ik.imagekit.io/navfnqmjg/4.5stars-2.143f68ab8e9a5e32f05a6ee28ed5f0dc.svg?updatedAt=1710751454827"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="cell-score"
                      style={{ color: Ratingcolor?.textcolor }}
                    >
                      {" "}
                      10
                    </div>
                    <div className="cell bonus-btn" style={{ width: "25%" }}>
                      <a
                        className="get-bonus-btn text-capitalize"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Get Bonus"
                        style={{
                          textDecoration: "none",
                          backgroundColor: ButtonStyledata?.backgroundcolor,
                          color: ButtonStyledata?.textcolor,
                          fontWeight: ButtonStyledata?.fontWeight,
                          fontSize: `${ButtonStyledata?.fontsize}px`,
                          fontFamily: ButtonStyledata?.fontFamily,
                          border: `${ButtonStyledata?.bordersize}px ${ButtonStyledata?.borderstyle} ${ButtonStyledata?.bordercolor}`,
                          borderTopLeftRadius: `${ButtonStyledata?.topleftradius}px`,
                          borderTopRightRadius: `${ButtonStyledata?.toprightradius}px `,
                          borderBottomLeftRadius: `${ButtonStyledata?.bottomleftradius}px`,
                          borderBottomRightRadius: `${ButtonStyledata?.bottomrightradius}px`,
                        }}
                      >
                        Join Now
                      </a>
                      <a
                        className="visit"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Visit Genesis Fantasy"
                        // style={{ color: gamelistStyle?.textcolor }}
                      >
                        {" "}
                        Visit Dream11{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
              <AccordionDetails>
                <Card variant="outlined" title="Template">
                  <CardContent sx={{ padding: "30px" }}>
                    <FormHelperText
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        mb: "10px",
                        color: "black",
                      }}
                    >
                      Table Heading
                    </FormHelperText>

                    <Grid container spacing={2}>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="backgroundcolor"
                          value={tablehead?.backgroundcolor}
                          onChange={handleChangehead}
                          style={{ height: "52px" ,cursor:'pointer'}}
                        />
                      </Grid>

                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          name="backgroundcolor"
                          label="Background Color"
                          value={tablehead?.backgroundcolor}
                          onChange={handleChangehead}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="textcolor"
                          value={tablehead?.textcolor}
                          onChange={handleChangehead}
                          style={{ height: "52px", cursor:'pointer' }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          fullWidth
                          label="Text Color"
                          name="textcolor"
                          value={tablehead?.textcolor}
                          onChange={handleChangehead}
                        />
                      </Grid>

                      <Grid item xs={2.5}>
                        <Autocomplete
                          value={tablehead?.fontFamily || null}
                          onChange={(event, newValue) =>
                            setTableHead({
                              ...tablehead,
                              fontFamily: newValue,
                            })
                          }
                          options={fonts}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Font Family" />
                          )}
                          getOptionLabel={(font) => font}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderOption={(props, font) => (
                            <li {...props} style={{ fontFamily: font }}>
                              {font}
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <TextField
                          type="number"
                          fullWidth
                          name="fontsize"
                          value={tablehead?.fontsize}
                          label="Font Size"
                          variant="outlined"
                          focused
                          onChange={handleChangehead}
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <Autocomplete
                          fullWidth
                          focused
                          options={fontWeights}
                          getOptionLabel={(option) => option}
                          value={tablehead?.fontWeight}
                          onChange={(event, newValue) => {
                            setTableHead((prevState) => ({
                              ...prevState,
                              fontWeight: newValue,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Font Weight"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                    <FormHelperText
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Table Head Radius
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Top Left corner"
                      name="topleftradius"
                      value={tablehead?.topleftradius}
                      onChange={handleChangehead}
                      focused
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Top Right corner"
                      name="toprightradius"
                      value={tablehead?.toprightradius}
                      onChange={handleChangehead}
                      focused
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Bottom right corner"
                      name="bottomrightradius"
                      value={tablehead?.bottomrightradius}
                      onChange={handleChangehead}
                      focused
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Bottom left corner"
                      name="bottomleftradius"
                      value={tablehead?.bottomleftradius}
                      onChange={handleChangehead}
                      focused
                    />
                  </Grid>
                  {/* <Grid container spacing={2}> */}
                  <Grid item xs={12}>
                    <FormHelperText
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Table Head Border
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Size"
                      name="bordersize"
                      onChange={handleChangehead}
                      value={tablehead?.bordersize}
                      focused
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="border-style-label" variant='outlined'>Border Style</InputLabel> */}
                      <Select
                        labelId="border-style-label"
                        id="border-style"
                        fullWidth
                        defaultValue="solid"
                        name="borderstyle"
                        value={tablehead?.borderstyle || 'select'}
                        onChange={handleChangehead}
                        focused
                      >
                        <MenuItem value="select" disabled>
                          <em vla>Select Border Style</em>
                        </MenuItem>
                        <MenuItem value="solid">Solid</MenuItem>
                        <MenuItem value="dashed">Dashed</MenuItem>
                        <MenuItem value="double">Double</MenuItem>
                        <MenuItem value="dotted">Dotted </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0.5}>
                    <input
                      type="Color"
                      fullWidth
                      name="bordercolor"
                      style={{ height: "52px" }}
                      onChange={handleChangehead}
                      value={tablehead?.bordercolor}
                      focused
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="text"
                      fullWidth
                      label="Border Color"
                      name="bordercolor"
                      onChange={handleChangehead}
                      value={tablehead?.bordercolor}
                      focused
                    />
                  </Grid>
                    </Grid>
                    <Box sx={{ mt: "25px" }}>
                      {headid ? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() => handleupdatetablehead(tablehead?._id)}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handleSubmittablehead()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
            {/* Gamelist style */}
            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel5-header"
                sx={{ fontWeight: "600" }}
              >
                Game List Style
              </AccordionSummary>
              <div>
            <div
              className="content Text-container"
              style={{ marginLeft: "10px" }}
            >
              <div className="ppc-dl col-md-12">
                <div
                  className="table-headers casino row-head"
                  style={{
                    backgroundColor: tablehead?.backgroundcolor,
                    fontSize: `${tablehead?.fontsize}px`,
                    fontWeight: tablehead?.fontWeight,
                    fontFamily: tablehead?.fontFamily,
                    color: tablehead?.textcolor,
                    border: `${tablehead?.bordersize}px ${
                      tablehead?.borderstyle || "select"
                    } ${tablehead?.bordercolor}`,
                    borderTopLeftRadius: `${tablehead?.topleftradius}px`,
                    borderTopRightRadius: `${tablehead?.toprightradius}px `,
                    borderBottomLeftRadius: `${tablehead?.bottomleftradius}px`,
                    borderBottomRightRadius: `${tablehead?.bottomrightradius}px`,
                  }}
                >
                  <div className="cell logo">Sites </div>
                  <div className="cell bonus">Bonus</div>
                  <div className="cell ratinghead">Rating</div>
                  <div className="cell features  d-lg-flex">Rating</div>
                  <div className="cell get-bonus">Play Now</div>
                </div>
                <div className="dl-list casino row">
                  <div
                    className="table-row"
                    data-brand-position="“9”"
                    data-brand-title=" Fantasy"
                    style={{
                      backgroundColor: gamelistStyle?.Backgroundcolor,
                      padding: "0",
                      borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                      borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      borderTopRightRadius: `${gamelistStyle?.toprightradius}px`,
                      borderBottomRightRadius: `${gamelistStyle?.bottomrightradius}px`,
                      border: `${gamelistStyle?.bordersize}px ${gamelistStyle?.borderstyle} ${gamelistStyle?.bordercolor}`,
                    }}
                  >
                    {/* TableLogo?.backgroundcolor */}
                    <div
                      className="cell-logo"
                      style={{
                        backgroundColor: TableLogo?.backgroundcolor,
                        borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                        borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      }}
                    >
                      <a
                        className="brand-name-logo-link"
                        href={""}
                        rel="nofollow"
                        target="_blank"
                      >
                        <img
                          className="lazy"
                          alt=" Fantasy"
                          src="https://ik.imagekit.io/navfnqmjg/dream11.svg?updatedAt=1710412933809"
                        />
                      </a>
                      <div className="white-box" />
                    </div>
                    {/* gamelistStyle?.textcolor */}
                    <div className="cell-bonus " style={{ width: "28%" }}>
                      <p
                        style={{
                          textAlign: "center",
                          color: gamelistStyle?.textcolor,
                          fontSize: "14px",
                        }}
                      >
                        PLAY FANTASY CRICKET & WIN
                        <br />
                        <b
                          className="big-bonus1"
                          style={{ color: gamelistStyle?.textcolor }}
                        >
                          100000
                          <br />
                        </b>
                        Lightning Fast Withdrawals
                      </p>
                      <div className="legal">
                        <a href={""} target="_blank">
                          <p
                            className="link-upi"
                            style={{
                              color: gamelistStyle?.textcolor,
                              fontSize: "11px",
                            }}
                          >
                            UPI &amp; Netbanking Accepted
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="cell-rating">
                      <div className="score-stars">
                        <img
                          className="lazy"
                          alt="Play Fantasy"
                          src={
                            "https://ik.imagekit.io/navfnqmjg/4.5stars-2.143f68ab8e9a5e32f05a6ee28ed5f0dc.svg?updatedAt=1710751454827"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="cell-score"
                      style={{ color: Ratingcolor?.textcolor }}
                    >
                      {" "}
                      10
                    </div>
                    <div className="cell bonus-btn" style={{ width: "25%" }}>
                      <a
                        className="get-bonus-btn text-capitalize"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Get Bonus"
                        style={{
                          textDecoration: "none",
                          backgroundColor: ButtonStyledata?.backgroundcolor,
                          color: ButtonStyledata?.textcolor,
                          fontWeight: ButtonStyledata?.fontWeight,
                          fontSize: `${ButtonStyledata?.fontsize}px`,
                          fontFamily: ButtonStyledata?.fontFamily,
                          border: `${ButtonStyledata?.bordersize}px ${ButtonStyledata?.borderstyle} ${ButtonStyledata?.bordercolor}`,
                          borderTopLeftRadius: `${ButtonStyledata?.topleftradius}px`,
                          borderTopRightRadius: `${ButtonStyledata?.toprightradius}px `,
                          borderBottomLeftRadius: `${ButtonStyledata?.bottomleftradius}px`,
                          borderBottomRightRadius: `${ButtonStyledata?.bottomrightradius}px`,
                        }}
                      >
                        Join Now
                      </a>
                      <a
                        className="visit"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Visit Genesis Fantasy"
                        // style={{ color: gamelistStyle?.textcolor }}
                      >
                        {" "}
                        Visit Dream11{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
              <AccordionDetails>
                   <Card variant="outlined" sx={{ mt: "20px" }}>
                   <CardContent sx={{ padding: "30px" }}>
                   <Grid container spacing={2}>
              <Grid item xs={12}>
                    <FormHelperText sx={{ fontSize: '16px', fontWeight: '600', color: 'black' }}>Game List Background</FormHelperText>
                  </Grid>
                  <Grid item xs={0.5}>
                    <input
                      type="Color"
                      fullWidth
                      name="Backgroundcolor"
                      style={{ height: '52px', cursor:'pointer' }}
                      onChange={handleChangegamelist}
                      value={gamelistStyle?.Backgroundcolor}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="text"
                      fullWidth
                      label="Background Color"
                      name="Backgroundcolor"
                      onChange={handleChangegamelist}
                      value={gamelistStyle?.Backgroundcolor}
                      focused
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <input
                      type="Color"
                      fullWidth
                      name="textcolor"
                      style={{ height: '52px', cursor:'pointer' }}
                      onChange={handleChangegamelist}
                      value={gamelistStyle?.textcolor}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="text"
                      fullWidth
                      label="Text Color"
                      name="textcolor"
                      onChange={handleChangegamelist}
                      value={gamelistStyle?.textcolor}
                      focused
                    />
                  </Grid>
                </Grid>
                     <Grid container spacing={2}>
                       <Grid item xs={12}>
                         <FormHelperText
                           sx={{
                             fontSize: "16px",
                             fontWeight: "600",
                             color: "black",
                           }}
                         >
                           Game List Radius
                         </FormHelperText>
                       </Grid>
                       <Grid item xs={2}>
                         <TextField
                           type="number"
                           fullWidth
                           label="Top Left corner"
                           name="topleftradius"
                           value={gamelistStyle?.topleftradius}
                           onChange={handleChangegamelist}
                           focused
                         />
                       </Grid>
                       <Grid item xs={2}>
                         <TextField
                           type="number"
                           fullWidth
                           label="Top Right corner"
                           name="toprightradius"
                           value={gamelistStyle?.toprightradius}
                           onChange={handleChangegamelist}
                           focused
                         />
                       </Grid>
                       <Grid item xs={2}>
                         <TextField
                           type="number"
                           fullWidth
                           label="Bottom right corner"
                           name="bottomrightradius"
                           value={gamelistStyle?.bottomrightradius}
                           onChange={handleChangegamelist}
                           focused
                         />
                       </Grid>
                       <Grid item xs={2}>
                         <TextField
                           type="number"
                           fullWidth
                           label="Bottom left corner"
                           name="bottomleftradius"
                           value={gamelistStyle?.bottomleftradius}
                           onChange={handleChangegamelist}
                           focused
                         />
                       </Grid>
                       <Grid item xs={12}>
                         <FormHelperText sx={{ fontSize: '16px', fontWeight: '600', color: 'black' }}>Game List Border</FormHelperText>
                       </Grid>
                       <Grid item xs={2}>
                         <TextField
                           type="number"
                           fullWidth
                           label="Size"
                           name="bordersize"
                           onChange={handleChangegamelist}
                           value={gamelistStyle?.bordersize}
                           focused
                         />
                       </Grid>
                       <Grid item xs={2}>
                         <FormControl fullWidth>
                           <Select
                             labelId="border-style-label"
                             id="border-style"
                             fullWidth
                             focused
                             name="borderstyle"
                             value={gamelistStyle?.borderstyle || 'select'}
                             onChange={handleChangegamelist}
                           >
                             <MenuItem value="select" disabled>
                               <em>Select Border Style</em>
                             </MenuItem>
                             <MenuItem value="solid">Solid</MenuItem>
                             <MenuItem value="dashed">Dashed</MenuItem>
                             <MenuItem value="double">Double</MenuItem>
                             <MenuItem value="dotted">Dotted </MenuItem>
                           </Select>
                         </FormControl>
                       </Grid>
                       <Grid item xs={0.5}>
                         <input
                           type="Color"
                           fullWidth
                           name="bordercolor"
                           style={{ height: '52px' }}
                           onChange={handleChangegamelist}
                           value={gamelistStyle?.bordercolor}
                         />
                       </Grid>
                       <Grid item xs={2}>
                         <TextField
                           type="text"
                           fullWidth
                           label="Border Color"
                           name="bordercolor"
                           onChange={handleChangegamelist}
                           value={gamelistStyle?.bordercolor}
                           focused
                         />
                       </Grid>
                     </Grid>
                     <Box sx={{ mt: "25px" }}>
                       {gamelistid ? (
                         <Button
                           variant="contained"
                           sx={{ padding: "5px 20px" }}
                           onClick={() => Updategamelistdata(gamelistid)}
                         >
                           Update
                         </Button>
                       ) : (
                         <Button
                           variant="contained"
                           sx={{ padding: "5px 20px", mr: "20px" }}
                           onClick={() => handleSubmitgamelist()}
                         >
                           Save
                         </Button>
                       )}
                     </Box>
                   </CardContent>
                 </Card>
                {/* )} */}
               
              </AccordionDetails>
            </Accordion>

            {/* Table Logo background  */}
            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: "600" }}
              >
                Logo Background
              </AccordionSummary>
              <div>
            <div
              className="content Text-container"
              style={{ marginLeft: "10px" }}
            >
              <div className="ppc-dl col-md-12">
                <div
                  className="table-headers casino row-head"
                  style={{
                    backgroundColor: tablehead?.backgroundcolor,
                    fontSize: `${tablehead?.fontsize}px`,
                    fontWeight: tablehead?.fontWeight,
                    fontFamily: tablehead?.fontFamily,
                    color: tablehead?.textcolor,
                    border: `${tablehead?.bordersize}px ${
                      tablehead?.borderstyle || "select"
                    } ${tablehead?.bordercolor}`,
                    borderTopLeftRadius: `${tablehead?.topleftradius}px`,
                    borderTopRightRadius: `${tablehead?.toprightradius}px `,
                    borderBottomLeftRadius: `${tablehead?.bottomleftradius}px`,
                    borderBottomRightRadius: `${tablehead?.bottomrightradius}px`,
                  }}
                >
                  <div className="cell logo">Sites </div>
                  <div className="cell bonus">Bonus</div>
                  <div className="cell ratinghead">Rating</div>
                  <div className="cell features  d-lg-flex">Rating</div>
                  <div className="cell get-bonus">Play Now</div>
                </div>
                <div className="dl-list casino row">
                  <div
                    className="table-row"
                    data-brand-position="“9”"
                    data-brand-title=" Fantasy"
                    style={{
                      backgroundColor: gamelistStyle?.Backgroundcolor,
                      padding: "0",
                      borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                      borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      borderTopRightRadius: `${gamelistStyle?.toprightradius}px`,
                      borderBottomRightRadius: `${gamelistStyle?.bottomrightradius}px`,
                      border: `${gamelistStyle?.bordersize}px ${gamelistStyle?.borderstyle} ${gamelistStyle?.bordercolor}`,
                    }}
                  >
                    {/* TableLogo?.backgroundcolor */}
                    <div
                      className="cell-logo"
                      style={{
                        backgroundColor: TableLogo?.backgroundcolor,
                        borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                        borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      }}
                    >
                      <a
                        className="brand-name-logo-link"
                        href={""}
                        rel="nofollow"
                        target="_blank"
                      >
                        <img
                          className="lazy"
                          alt=" Fantasy"
                          src="https://ik.imagekit.io/navfnqmjg/dream11.svg?updatedAt=1710412933809"
                        />
                      </a>
                      <div className="white-box" />
                    </div>
                    {/* gamelistStyle?.textcolor */}
                    <div className="cell-bonus " style={{ width: "28%" }}>
                      <p
                        style={{
                          textAlign: "center",
                          color: gamelistStyle?.textcolor,
                          fontSize: "14px",
                        }}
                      >
                        PLAY FANTASY CRICKET & WIN
                        <br />
                        <b
                          className="big-bonus1"
                          style={{ color: gamelistStyle?.textcolor }}
                        >
                          100000
                          <br />
                        </b>
                        Lightning Fast Withdrawals
                      </p>
                      <div className="legal">
                        <a href={""} target="_blank">
                          <p
                            className="link-upi"
                            style={{
                              color: gamelistStyle?.textcolor,
                              fontSize: "11px",
                            }}
                          >
                            UPI &amp; Netbanking Accepted
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="cell-rating">
                      <div className="score-stars">
                        <img
                          className="lazy"
                          alt="Play Fantasy"
                          src={
                            "https://ik.imagekit.io/navfnqmjg/4.5stars-2.143f68ab8e9a5e32f05a6ee28ed5f0dc.svg?updatedAt=1710751454827"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="cell-score"
                      style={{ color: Ratingcolor?.textcolor }}
                    >
                      {" "}
                      10
                    </div>
                    <div className="cell bonus-btn" style={{ width: "25%" }}>
                      <a
                        className="get-bonus-btn text-capitalize"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Get Bonus"
                        style={{
                          textDecoration: "none",
                          backgroundColor: ButtonStyledata?.backgroundcolor,
                          color: ButtonStyledata?.textcolor,
                          fontWeight: ButtonStyledata?.fontWeight,
                          fontSize: `${ButtonStyledata?.fontsize}px`,
                          fontFamily: ButtonStyledata?.fontFamily,
                          border: `${ButtonStyledata?.bordersize}px ${ButtonStyledata?.borderstyle} ${ButtonStyledata?.bordercolor}`,
                          borderTopLeftRadius: `${ButtonStyledata?.topleftradius}px`,
                          borderTopRightRadius: `${ButtonStyledata?.toprightradius}px `,
                          borderBottomLeftRadius: `${ButtonStyledata?.bottomleftradius}px`,
                          borderBottomRightRadius: `${ButtonStyledata?.bottomrightradius}px`,
                        }}
                      >
                        Join Now
                      </a>
                      <a
                        className="visit"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Visit Genesis Fantasy"
                        // style={{ color: gamelistStyle?.textcolor }}
                      >
                        {" "}
                        Visit Dream11{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
              <AccordionDetails>
                <Card variant="outlined" sx={{ mt: "20px" }}>
                  <CardContent sx={{ padding: "30px" }}>
                    <FormHelperText
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        mb: "10px",
                        color: "black",
                      }}
                    >
                      Logo Background
                    </FormHelperText>
                    <Grid container spacing={2}>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="backgroundcolor"
                          value={TableLogo?.backgroundcolor}
                          onChange={handleChangeLogo}
                          style={{ height: "52px" , cursor:'pointer'}}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          fullWidth
                          label="Background Color"
                          name="backgroundcolor"
                          value={TableLogo?.backgroundcolor}
                          onChange={handleChangeLogo}
                          focused
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: "25px" }}>
                      {Logoid?._id ? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() => UpdateTableLogodata(TableLogo?._id)}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handleSubmitTableLogo()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
            {/* Rating style*/}
            {/* Rating */}
        <Accordion sx={{ mt: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontWeight: "600" }}
          >
            Rating Text color
          </AccordionSummary>
          <div>
            <div
              className="content Text-container"
              style={{ marginLeft: "10px" }}
            >
              <div className="ppc-dl col-md-12">
                <div
                  className="table-headers casino row-head"
                  style={{
                    backgroundColor: tablehead?.backgroundcolor,
                    fontSize: `${tablehead?.fontsize}px`,
                    fontWeight: tablehead?.fontWeight,
                    fontFamily: tablehead?.fontFamily,
                    color: tablehead?.textcolor,
                    border: `${tablehead?.bordersize}px ${
                      tablehead?.borderstyle || "select"
                    } ${tablehead?.bordercolor}`,
                    borderTopLeftRadius: `${tablehead?.topleftradius}px`,
                    borderTopRightRadius: `${tablehead?.toprightradius}px `,
                    borderBottomLeftRadius: `${tablehead?.bottomleftradius}px`,
                    borderBottomRightRadius: `${tablehead?.bottomrightradius}px`,
                  }}
                >
                  <div className="cell logo">Sites </div>
                  <div className="cell bonus">Bonus</div>
                  <div className="cell ratinghead">Rating</div>
                  <div className="cell features  d-lg-flex">Rating</div>
                  <div className="cell get-bonus">Play Now</div>
                </div>
                <div className="dl-list casino row">
                  <div
                    className="table-row"
                    data-brand-position="“9”"
                    data-brand-title=" Fantasy"
                    style={{
                      backgroundColor: gamelistStyle?.Backgroundcolor,
                      padding: "0",
                      borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                      borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      borderTopRightRadius: `${gamelistStyle?.toprightradius}px`,
                      borderBottomRightRadius: `${gamelistStyle?.bottomrightradius}px`,
                      border: `${gamelistStyle?.bordersize}px ${gamelistStyle?.borderstyle} ${gamelistStyle?.bordercolor}`,
                    }}
                  >
                    {/* TableLogo?.backgroundcolor */}
                    <div
                      className="cell-logo"
                      style={{
                        backgroundColor: TableLogo?.backgroundcolor,
                        borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                        borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      }}
                    >
                      <a
                        className="brand-name-logo-link"
                        href={""}
                        rel="nofollow"
                        target="_blank"
                      >
                        <img
                          className="lazy"
                          alt=" Fantasy"
                          src="https://ik.imagekit.io/navfnqmjg/dream11.svg?updatedAt=1710412933809"
                        />
                      </a>
                      <div className="white-box" />
                    </div>
                    {/* gamelistStyle?.textcolor */}
                    <div className="cell-bonus " style={{ width: "28%" }}>
                      <p
                        style={{
                          textAlign: "center",
                          color: gamelistStyle?.textcolor,
                          fontSize: "14px",
                        }}
                      >
                        PLAY FANTASY CRICKET & WIN
                        <br />
                        <b
                          className="big-bonus1"
                          style={{ color: gamelistStyle?.textcolor }}
                        >
                          100000
                          <br />
                        </b>
                        Lightning Fast Withdrawals
                      </p>
                      <div className="legal">
                        <a href={""} target="_blank">
                          <p
                            className="link-upi"
                            style={{
                              color: gamelistStyle?.textcolor,
                              fontSize: "11px",
                            }}
                          >
                            UPI &amp; Netbanking Accepted
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="cell-rating">
                      <div className="score-stars">
                        <img
                          className="lazy"
                          alt="Play Fantasy"
                          src={
                            "https://ik.imagekit.io/navfnqmjg/4.5stars-2.143f68ab8e9a5e32f05a6ee28ed5f0dc.svg?updatedAt=1710751454827"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="cell-score"
                      style={{ color: Ratingcolor?.textcolor }}
                    >
                      {" "}
                      10
                    </div>
                    <div className="cell bonus-btn" style={{ width: "25%" }}>
                      <a
                        className="get-bonus-btn text-capitalize"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Get Bonus"
                        style={{
                          textDecoration: "none",
                          backgroundColor: ButtonStyledata?.backgroundcolor,
                          color: ButtonStyledata?.textcolor,
                          fontWeight: ButtonStyledata?.fontWeight,
                          fontSize: `${ButtonStyledata?.fontsize}px`,
                          fontFamily: ButtonStyledata?.fontFamily,
                          border: `${ButtonStyledata?.bordersize}px ${ButtonStyledata?.borderstyle} ${ButtonStyledata?.bordercolor}`,
                          borderTopLeftRadius: `${ButtonStyledata?.topleftradius}px`,
                          borderTopRightRadius: `${ButtonStyledata?.toprightradius}px `,
                          borderBottomLeftRadius: `${ButtonStyledata?.bottomleftradius}px`,
                          borderBottomRightRadius: `${ButtonStyledata?.bottomrightradius}px`,
                        }}
                      >
                        Join Now
                      </a>
                      <a
                        className="visit"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Visit Genesis Fantasy"
                        // style={{ color: gamelistStyle?.textcolor }}
                      >
                        {" "}
                        Visit Dream11{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AccordionDetails>
            <Card variant="outlined" sx={{ mt: "20px" }}>
              <CardContent sx={{ padding: "30px" }}>
                <FormHelperText
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    mb: "10px",
                    color: "black",
                  }}
                >
                  Text color
                </FormHelperText>
                <Grid container spacing={2}>
                  <Grid item xs={0.5}>
                    <input
                      type="Color"
                      fullWidth
                      name="textcolor"
                      value={Ratingcolor?.textcolor}
                      onChange={handleChangeratingColor}
                      style={{ height: "52px" , cursor:'pointer'}}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      type="text"
                      fullWidth
                      label="Text Color"
                      name="textcolor"
                      value={Ratingcolor?.textcolor}
                      onChange={handleChangeratingColor}
                      focused
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mt: "25px" }}>
                  {ratingid?._id ? (
                    <Button
                      variant="contained"
                      sx={{ padding: "5px 20px" }}
                      onClick={() => UpdateRatingColor(Ratingcolor?._id)}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{ padding: "5px 20px", mr: "20px" }}
                      onClick={() => handleSubmitRatingcolor()}
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </AccordionDetails>
        </Accordion>
      


            {/* Get Bonus Button */}
            <Accordion sx={{ mt: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: "600" }}
              >
                Get Bonus Button
              </AccordionSummary>
              <div>
            <div
              className="content Text-container"
              style={{ marginLeft: "10px" }}
            >
              <div className="ppc-dl col-md-12">
                <div
                  className="table-headers casino row-head"
                  style={{
                    backgroundColor: tablehead?.backgroundcolor,
                    fontSize: `${tablehead?.fontsize}px`,
                    fontWeight: tablehead?.fontWeight,
                    fontFamily: tablehead?.fontFamily,
                    color: tablehead?.textcolor,
                    border: `${tablehead?.bordersize}px ${
                      tablehead?.borderstyle || "select"
                    } ${tablehead?.bordercolor}`,
                    borderTopLeftRadius: `${tablehead?.topleftradius}px`,
                    borderTopRightRadius: `${tablehead?.toprightradius}px `,
                    borderBottomLeftRadius: `${tablehead?.bottomleftradius}px`,
                    borderBottomRightRadius: `${tablehead?.bottomrightradius}px`,
                  }}
                >
                  <div className="cell logo">Sites </div>
                  <div className="cell bonus">Bonus</div>
                  <div className="cell ratinghead">Rating</div>
                  <div className="cell features  d-lg-flex">Rating</div>
                  <div className="cell get-bonus">Play Now</div>
                </div>
                <div className="dl-list casino row">
                  <div
                    className="table-row"
                    data-brand-position="“9”"
                    data-brand-title=" Fantasy"
                    style={{
                      backgroundColor: gamelistStyle?.Backgroundcolor,
                      padding: "0",
                      borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                      borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      borderTopRightRadius: `${gamelistStyle?.toprightradius}px`,
                      borderBottomRightRadius: `${gamelistStyle?.bottomrightradius}px`,
                      border: `${gamelistStyle?.bordersize}px ${gamelistStyle?.borderstyle} ${gamelistStyle?.bordercolor}`,
                    }}
                  >
                    {/* TableLogo?.backgroundcolor */}
                    <div
                      className="cell-logo"
                      style={{
                        backgroundColor: TableLogo?.backgroundcolor,
                        borderTopLeftRadius: `${gamelistStyle?.topleftradius}px`,
                        borderBottomLeftRadius: `${gamelistStyle?.bottomleftradius}px`,
                      }}
                    >
                      <a
                        className="brand-name-logo-link"
                        href={""}
                        rel="nofollow"
                        target="_blank"
                      >
                        <img
                          className="lazy"
                          alt=" Fantasy"
                          src="https://ik.imagekit.io/navfnqmjg/dream11.svg?updatedAt=1710412933809"
                        />
                      </a>
                      <div className="white-box" />
                    </div>
                    {/* gamelistStyle?.textcolor */}
                    <div className="cell-bonus " style={{ width: "28%" }}>
                      <p
                        style={{
                          textAlign: "center",
                          color: gamelistStyle?.textcolor,
                          fontSize: "14px",
                        }}
                      >
                        PLAY FANTASY CRICKET & WIN
                        <br />
                        <b
                          className="big-bonus1"
                          style={{ color: gamelistStyle?.textcolor }}
                        >
                          100000
                          <br />
                        </b>
                        Lightning Fast Withdrawals
                      </p>
                      <div className="legal">
                        <a href={""} target="_blank">
                          <p
                            className="link-upi"
                            style={{
                              color: gamelistStyle?.textcolor,
                              fontSize: "11px",
                            }}
                          >
                            UPI &amp; Netbanking Accepted
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="cell-rating">
                      <div className="score-stars">
                        <img
                          className="lazy"
                          alt="Play Fantasy"
                          src={
                            "https://ik.imagekit.io/navfnqmjg/4.5stars-2.143f68ab8e9a5e32f05a6ee28ed5f0dc.svg?updatedAt=1710751454827"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="cell-score"
                      style={{ color: Ratingcolor?.textcolor }}
                    >
                      {" "}
                      10
                    </div>
                    <div className="cell bonus-btn" style={{ width: "25%" }}>
                      <a
                        className="get-bonus-btn text-capitalize"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Get Bonus"
                        style={{
                          textDecoration: "none",
                          backgroundColor: ButtonStyledata?.backgroundcolor,
                          color: ButtonStyledata?.textcolor,
                          fontWeight: ButtonStyledata?.fontWeight,
                          fontSize: `${ButtonStyledata?.fontsize}px`,
                          fontFamily: ButtonStyledata?.fontFamily,
                          border: `${ButtonStyledata?.bordersize}px ${ButtonStyledata?.borderstyle} ${ButtonStyledata?.bordercolor}`,
                          borderTopLeftRadius: `${ButtonStyledata?.topleftradius}px`,
                          borderTopRightRadius: `${ButtonStyledata?.toprightradius}px `,
                          borderBottomLeftRadius: `${ButtonStyledata?.bottomleftradius}px`,
                          borderBottomRightRadius: `${ButtonStyledata?.bottomrightradius}px`,
                        }}
                      >
                        Join Now
                      </a>
                      <a
                        className="visit"
                        href={""}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title="Visit Genesis Fantasy"
                        // style={{ color: gamelistStyle?.textcolor }}
                      >
                        {" "}
                        Visit Dream11{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
              <AccordionDetails>
                <Card variant="outlined" sx={{ mt: "20px" }}>
                  <CardContent sx={{ padding: "30px" }}>
                    <FormHelperText
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        mb: "10px",
                        color: "black",
                      }}
                    >
                      Get Bonus Button
                    </FormHelperText>
                    <Grid container spacing={2}>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="backgroundcolor"
                          value={ButtonStyledata?.backgroundcolor}
                          onChange={handleChangeButton}
                          style={{ height: "52px", cursor:'pointer' }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          fullWidth
                          label="Background Color"
                          name="backgroundcolor"
                          value={ButtonStyledata?.backgroundcolor}
                          onChange={handleChangeButton}
                          focused
                        />
                      </Grid>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="textcolor"
                          value={ButtonStyledata?.textcolor}
                          onChange={handleChangeButton}
                          style={{ height: "52px", cursor:'pointer' }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          type="text"
                          fullWidth
                          label="Text Color"
                          name="textcolor"
                          value={ButtonStyledata?.textcolor}
                          onChange={handleChangeButton}
                          focused
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        {/* <FormHelperText className="label">Select Font-Family</FormHelperText> */}
                        <Autocomplete
                          value={ButtonStyledata?.fontFamily || null}
                          onChange={(event, newValue) =>
                            setButtonStyledata({
                              ...ButtonStyledata,
                              fontFamily: newValue,
                            })
                          }
                          options={fonts}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Font Family" />
                          )}
                          getOptionLabel={(font) => font}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderOption={(props, font) => (
                            <li {...props} style={{ fontFamily: font }}>
                              {font}
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <TextField
                          type="number"
                          fullWidth
                          name="fontsize"
                          value={ButtonStyledata?.fontsize}
                          label="Font Size"
                          variant="outlined"
                          focused
                          onChange={handleChangeButton}
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <Autocomplete
                          fullWidth
                          options={fontWeights}
                          getOptionLabel={(option) => option}
                          value={ButtonStyledata?.fontWeight || null}
                          focused
                          onChange={(event, newValue) => {
                            setButtonStyledata({
                              ...ButtonStyledata,
                              fontWeight: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Font Weight"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormHelperText
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "black",
                          }}
                        >
                          Button Radius
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Top Left corner"
                          name="topleftradius"
                          value={ButtonStyledata?.topleftradius}
                          onChange={handleChangeButton}
                          focused
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Top Right corner"
                          name="toprightradius"
                          value={ButtonStyledata?.toprightradius}
                          onChange={handleChangeButton}
                          focused
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Bottom right corner"
                          name="bottomrightradius"
                          value={ButtonStyledata?.bottomrightradius}
                          onChange={handleChangeButton}
                          focused
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Bottom left corner"
                          name="bottomleftradius"
                          value={ButtonStyledata?.bottomleftradius}
                          onChange={handleChangeButton}
                          focused
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormHelperText
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "black",
                          }}
                        >
                          Button Border
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Size"
                          name="bordersize"
                          onChange={handleChangeButton}
                          value={ButtonStyledata?.bordersize}
                          focused
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          {/* <InputLabel id="border-style-label" variant='outlined'>Border Style</InputLabel> */}
                          <Select
                            labelId="border-style-label"
                            id="border-style"
                            fullWidth
                            defaultValue="solid"
                            name="borderstyle"
                            value={ButtonStyledata?.borderstyle || "solid"}
                            onChange={handleChangeButton}
                            focused
                          >
                            <MenuItem value="" disabled>
                              <em>Select Border Style</em>
                            </MenuItem>
                            <MenuItem value="solid">Solid</MenuItem>
                            <MenuItem value="dashed">Dashed</MenuItem>
                            <MenuItem value="double">Double</MenuItem>
                            <MenuItem value="dotted">Dotted </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={0.5}>
                        <input
                          type="Color"
                          fullWidth
                          name="bordercolor"
                          style={{ height: "52px" }}
                          onChange={handleChangeButton}
                          value={ButtonStyledata?.bordercolor}
                          focused
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          type="text"
                          fullWidth
                          label="Border Color"
                          name="bordercolor"
                          onChange={handleChangeButton}
                          value={ButtonStyledata?.bordercolor}
                          focused
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: "25px" }}>
                      {btnId?._id ? (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px" }}
                          onClick={() =>
                            handleUpdateButton(ButtonStyledata?._id)
                          }
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ padding: "5px 20px", mr: "20px" }}
                          onClick={() => handleSubmitButton()}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
          </Box>
          <ToastContainer />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
